/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $OfficesList = {
  type: 'array',
  contains: {
    type: 'Office',
  },
} as const;
