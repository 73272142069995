import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import isEmptyObject from '@Utils/isEmptyObject';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

export interface GoogleSignInState {
  userInfo: {
    resourceNameId: string | null;
    email_verified: boolean;
    email: string | null;
  };
  isValidUser: boolean;
  isAccessTokenExpired: boolean;
  isSignedIn: boolean;
  jwt: string | null;
}

export const SignInDataInitialState: GoogleSignInState = {
  userInfo: {
    resourceNameId: null,
    email_verified: false,
    email: null,
  },
  isValidUser: false,
  isAccessTokenExpired: false,
  isSignedIn: false,
  jwt: null,
};

export function checkIsAccessTokenExpired(token) {
  const refreshTokenTimeBuffer = 10; // 10 seconds
  if (!token) return true;

  const dayParsedExpSubs = dayjs.unix(token.exp).subtract(refreshTokenTimeBuffer, 'second');
  const accessTokenExpDate = dayParsedExpSubs.valueOf();
  const nowUnix = dayjs().valueOf();

  const expired = accessTokenExpDate <= nowUnix;
  if (expired) {
    // @ts-ignore
    window?.google?.accounts?.id?.disableAutoSelect();
    sessionStorage.setItem('credentialData', '');
  }

  return expired;
}

export function parseJWT(token) {
  let tokenData = {};

  try {
    tokenData = JSON.parse(atob(token?.split('.')[1]));
  } catch (error) {
    return 'token is not valid';
  }

  return tokenData;
}

export function validateTokenUser(token) {
  if (token && !isEmptyObject(token)) {
    const isValid = token?.email_verified && token?.email?.length > 0;
    if (!isValid) {
      console.error('Token `Email is not verified` and/or `no email` property.');
    }
    return isValid;
  }

  console.error('No auth token received.');
  return false;
}

export const handleCredentialData = (token) => {
  const credentialData: any = parseJWT(token);
  const isValidUser = validateTokenUser(credentialData);
  const isAccessTokenExpired = checkIsAccessTokenExpired(credentialData);

  return {
    userInfo: {
      resourceNameId: `${credentialData.sub}`,
      email_verified: credentialData.email_verified,
      email: credentialData.email,
    },
    isValidUser: isValidUser,
    isAccessTokenExpired: isAccessTokenExpired,
    isSignedIn: isValidUser && !isAccessTokenExpired,
    jwt: token,
  };
};

const signInDataSlice = createSlice({
  name: 'signInData',
  initialState: SignInDataInitialState as GoogleSignInState,
  reducers: {
    handleGAuthCallback: (_, action: PayloadAction<string>) => {
      const token = action?.payload || {};
      sessionStorage.setItem('credentialData', token);
      return handleCredentialData(token);
    },
    setAccessData: (_, action: PayloadAction<string>) => {
      const token = action?.payload || {};
      return handleCredentialData(token);
    },
  },
});

export const { handleGAuthCallback, setAccessData } = signInDataSlice.actions;

export default signInDataSlice.reducer;
