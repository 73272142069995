/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Name = {
  properties: {
    middleName: {
      type: 'string',
    },
    honorificSuffix: {
      type: 'string',
    },
    honorificPrefix: {
      type: 'string',
    },
    givenName: {
      type: 'string',
    },
    familyName: {
      type: 'string',
    },
    displayName: {
      type: 'string',
    },
  },
} as const;
