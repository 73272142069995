const useAuth = () => {
  const credentialsData = sessionStorage.getItem('credentialData') || null;
  const routeIsNotAuth = window.location.pathname !== '/authorization';

  return {
    isAuthorized: credentialsData?.length > 0,
    token: credentialsData,
    routeIsNotAuth,
  };
};

export default useAuth;
