import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import BackgroundRemoval from '@imgly/background-removal/dist/browser.js';

import { User, UsersList } from '@AppRoot/api/client';
import { BACKGROUND_OPTIONS } from '@Constants/backgroundColors';
import initialState from './initialState';
import sanitizePhone from '@Utils/sanitizePhone';
import isEmptyObject from '@Utils/isEmptyObject';

let config = {
  publicPath: `${self.location.origin}/imgly/`,
  debug: false,
  proxyToWorker: true,
  model: 'medium',
};

export interface UserMediaState {
  avatar: string | null;
  signature: string | null;
  originalPhotoId: string | null;
  uploading: {
    loading: boolean;
    loaded: boolean;
    error: SerializedError | null;
  };
  fetching: {
    loading: boolean;
    loaded: boolean;
    error: SerializedError | null;
  };
}

interface UserMediaResponse {
  avatar: string | null;
  signature: string | null;
  info: {
    url: string | null;
    firstTime: boolean;
    changed: boolean;
  };
}

interface UploadUserMediaParams {
  file: File | Blob | string;
  resourceNameId: string;
  color?: string;
}

interface GetUserMediaParams {
  resourceNameId: string;
}

interface BackgroundRemovalState {
  isProcessingImage: boolean | null;
  result: Blob | null;
  error: Error | null;
}

export const BackgroundRemovalRunner = new BackgroundRemoval(config);

const uploadUserMedia = createAsyncThunk<UserMediaResponse, UploadUserMediaParams>(
  'users/uploadUserMedia',
  async ({ file, resourceNameId, color = BACKGROUND_OPTIONS?.[0] }, { rejectWithValue }) => {
    let formFile = file;
    if (typeof file === 'string') {
      formFile = await urlToBlob(file);
    }

    try {
      const formData = new FormData();
      formData.append('file', formFile);

      const requestParams = {
        method: 'POST',
        body: formData,
      };

      const response = await fetch(
        `${window.location.origin}/um/${resourceNameId}?color=${color.replace('#', '')}`,
        requestParams,
      );

      return await response.json();
    } catch (error) {
      return rejectWithValue({
        error: 'Failed to upload user media',
      });
    }
  },
);

const getUserMedia = createAsyncThunk<UserMediaResponse, GetUserMediaParams>(
  'users/getUserMedia',
  async (resourceNameId, { rejectWithValue }) => {
    try {
      const response = await fetch(`${window.location.origin}/um/${resourceNameId}`);
      return await response.json();
    } catch (error) {
      rejectWithValue({
        error: 'Failed to download user media',
      });
    }
  },
);

interface updateAvatarDataParams {
  avatar: string;
  resourceNameId: string;
}

interface removeAvatarDataParams {
  photo: string;
  resourceNameId: string;
}

const updateAvatarData = createAsyncThunk(
  'users/updateLatestAvatarInfo',
  async (payload: updateAvatarDataParams, { rejectWithValue }) => {
    const { photo, resourceNameId } = payload;
    try {
      await fetch(`/um/${resourceNameId}/lastAvatar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          avatar: photo,
        }),
      });
      return;
    } catch (error) {
      rejectWithValue({
        error: 'Failed to download user media',
      });
    }
  },
);

const removeImageBackground = createAsyncThunk(
  'users/removeImageBackground',
  async ({ photo, resourceNameId }: removeAvatarDataParams) => {
    try {
      const resultWitoutBg = await BackgroundRemovalRunner.removeBackground(photo);
      if (resultWitoutBg) {
        // @ts-ignore
        const imageUrl = URL.createObjectURL(resultWitoutBg);
        return {
          photo: imageUrl,
          resourceNameId,
        };
      } else {
        return {
          photo,
          resourceNameId,
        };
      }
    } catch (error) {
      throw error;
    }
  },
);

const removeBackgroundAndUpdateMedia = createAsyncThunk(
  'users/removeBackgroundAndUpdateMedia',
  async (payload, { dispatch }) => {
    const { photo, resourceNameId } = payload;

    const { payload: result } = await dispatch(removeImageBackground({ photo, resourceNameId }));

    if (result?.photo && result?.resourceNameId === resourceNameId) {
      const { payload: photoData } = await dispatch(
        uploadUserMedia({
          file: result?.photo,
          resourceNameId: resourceNameId,
        }),
      );
      const cb = photoData?.avatar && photoData?.signature ? `?cb=${new Date().valueOf()}` : '';
      dispatch(
        updateSelectedUser({
          photo: photoData?.info?.url ? `${photoData?.info?.url}${cb}` : null,
          avatar: photoData?.avatar ? `${photoData?.avatar}${cb}` : null,
          signature: photoData?.signature ? `${photoData?.signature}${cb}` : null,
        }),
      );
    }
  },
);

async function urlToBlob(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
}

const usersSlice = createSlice({
  name: 'users',
  initialState: initialState.users,
  reducers: {
    setUsersList: (state, action: PayloadAction<UsersList>) => {
      state.list = action?.payload || {};
      state.options =
        action?.payload?.map((item) => ({
          label: item.fullName,
          value: item.resourceNameId,
        })) || [];
    },
    setSelectedUser: (state, action: PayloadAction<User>) => {
      if (isEmptyObject(action?.payload)) {
        state.selectedUser = initialState.users.selectedUser;
      } else {
        state.selectedUser = { ...state.selectedUser, ...action?.payload };
      }
    },
    updateSelectedUser: (state, action: PayloadAction<any>) => {
      state.selectedUser = { ...state.selectedUser, ...action?.payload };
    },
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.currentUser = { ...state.currentUser, ...action?.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadUserMedia.pending, (state) => {
        state.selectedUser.media.data = null;
        state.selectedUser.media.upload = {
          rejected: null,
          pending: true,
          fulfilled: false,
        };
      })
      .addCase(uploadUserMedia.fulfilled, (state, action) => {
        const { avatar, signature, info } = action?.payload || {};
        const cb = avatar && signature ? `?cb=${new Date().valueOf()}` : '';

        state.selectedUser.media.data = action?.payload || {};

        if (!info?.changed) {
          state.selectedUser.avatar = avatar ? `${avatar}${cb}` : null;
          state.selectedUser.signature = signature ? `${signature}${cb}` : null;
          state.selectedUser.avatarActualisation.pending = false;
        }

        state.selectedUser.media.upload = {
          rejected: null,
          pending: false,
          fulfilled: true,
        };
      })
      .addCase(uploadUserMedia.rejected, (state, action) => {
        state.selectedUser.media.data = null;
        state.selectedUser.media.upload = {
          pending: false,
          rejected: action?.payload,
          fulfilled: false,
        };
      })
      .addCase(getUserMedia.pending, (state) => {
        state.selectedUser.media.data = null;
        state.selectedUser.media.download = {
          rejected: null,
          pending: true,
          fulfilled: false,
        };
      })
      .addCase(getUserMedia.fulfilled, (state, action) => {
        const { avatar, signature, info } = action?.payload || {};
        const cb = avatar && signature ? `?cb=${new Date().valueOf()}` : '';
        
        if (!info?.changed) {
          state.selectedUser.avatar = avatar ? `${avatar}${cb}` : null;
          state.selectedUser.signature = signature ? `${signature}${cb}` : null;
          state.selectedUser.avatarActualisation.pending = false;
        }

        state.selectedUser.media.data = action?.payload || {};
        state.selectedUser.media.download = {
          rejected: null,
          pending: false,
          fulfilled: true,
        };
      })
      .addCase(getUserMedia.rejected, (state, action) => {
        state.selectedUser.media.data = null;
        state.selectedUser.media.download = {
          pending: false,
          rejected: action?.payload,
          fulfilled: false,
        };
      })
      .addCase(removeImageBackground.pending, (state) => {
        state.selectedUser.media.imageProcessing = {
          photo: null,
          pending: true,
          rejected: false,
          fulfilled: false,
        };
      })
      .addCase(removeImageBackground.fulfilled, (state, action) => {
        state.selectedUser.media.imageProcessing = {
          photo: action?.payload.photo,
          pending: false,
          rejected: false,
          fulfilled: true,
        };
      })
      .addCase(removeImageBackground.rejected, (state, action) => {
        state.selectedUser.media.imageProcessing = {
          photo: null,
          pending: false,
          rejected: action?.payload,
          fulfilled: false,
        };
      })
      .addCase(updateAvatarData.pending, (state) => {
        state.selectedUser.avatarActualisation = {
          photo: null,
          pending: true,
          rejected: false,
          fulfilled: false,
        };
      })
      .addCase(updateAvatarData.fulfilled, (state, action) => {
        state.selectedUser.avatarActualisation = {
          photo: action?.payload,
          pending: false,
          rejected: false,
          fulfilled: true,
        };
      })
      .addCase(updateAvatarData.rejected, (state, action) => {
        state.selectedUser.avatarActualisation = {
          photo: null,
          pending: false,
          rejected: action?.payload,
          fulfilled: false,
        };
      });
  },
});

export const { setUsersList, setSelectedUser, updateSelectedUser, setCurrentUser } =
  usersSlice.actions;

export {
  removeBackgroundAndUpdateMedia,
  uploadUserMedia,
  getUserMedia,
  updateAvatarData,
  removeImageBackground,
};

export default usersSlice.reducer;
