import { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import loadable from '@loadable/component';

import useAuth from '@Hooks/useAuth';
import history from '@AppRoot/history';
import isEmptyObject from '@Utils/isEmptyObject';

import { OpenAPI, UsersService } from '@API/client';

import { useAppDispatch, useAppSelector } from '@State/store';
import { setSelectedUser, setCurrentUser, updateAvatarData } from '@State/usersSlice';

const Loader = loadable(() => import('@Components/Loader'));

const PrivateRoute = (props) => {
  const { path, exact, strict, location, render, permissions, computedMatch } = props;
  const {
    params: { resourceNameId, auth, viewType },
  } = computedMatch;

  const { isAuthorized, token } = useAuth();

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.users.currentUser);
  const selectedUser = useAppSelector((state) => state.users.selectedUser);

  const [isAccessGranted, setIsAccessGranted] = useState(currentUser?.isEditor || false);

  const hasRequiredPermissions = viewType === 'user-eitor' && permissions?.length > 0;
  const isAuthPage = auth?.length > 0 && auth === 'authorization';
  const isLoadingProfile = !currentUser?.resourceName;
  const permissionsOK = hasRequiredPermissions ? isAccessGranted && isAuthorized : isAuthorized;

  useEffect(() => {
    if (isAuthorized) {
      if (!OpenAPI?.HEADERS?.authorization) {
        OpenAPI.HEADERS = {
          authorization: `${token}`,
        };
      }
    } else {
      if (!isAuthPage) {
        history.push('/authorization');
        history.go(0);
      }
    }
  }, [isAuthorized, history, permissions, resourceNameId, currentUser, selectedUser]);

  useEffect(() => {
    if (isAuthorized && !currentUser?.resourceNameId) {
      UsersService.getInfoMe().then((response) => {
        dispatch(setCurrentUser(response));
        dispatch(
          updateAvatarData({
            photo: response.photo,
            resourceNameId: response.resourceNameId,
          }),
        );

        if (!selectedUser?.resourceNameId) {
          if (
            resourceNameId === 'me' ||
            (currentUser?.resourceNameId === resourceNameId && viewType !== 'user-editor')
          ) {
            dispatch(setSelectedUser(response));
          }
        }

        hasRequiredPermissions &&
          permissions.find((permission) => permission === 'isEditor') &&
          setIsAccessGranted(response.isEditor);
      });
    }
  }, [isAuthorized, currentUser]);

  useEffect(() => {
    if (isAuthorized && !selectedUser?.resourceNameId) {
      if (resourceNameId && resourceNameId !== 'me') {
        UsersService.getInfoUser({ resourceNameId }).then((response) => {
          dispatch(
            updateAvatarData({
              photo: response.photo,
              resourceNameId: response.resourceNameId,
            }),
          );
          dispatch(setSelectedUser(response));
        });
      }
    }
  }, [isAuthorized, selectedUser]);

  useEffect(() => {
    if (currentUser?.resourceName) {
      setIsAccessGranted(currentUser?.isEditor);
    }
  }, [currentUser, selectedUser]);

  useEffect(() => {
    if (
      isAuthorized &&
      !currentUser.isEditor &&
      !isLoadingProfile &&
      !permissionsOK &&
      !isEmptyObject(currentUser)
    ) {
      history.push('/page403');
      history.go(0);
    }
  }, [isAuthorized, isAccessGranted, permissionsOK, isLoadingProfile, currentUser]);

  if (isAuthorized && isLoadingProfile) {
    return <Loader />;
  }

  if (isAuthPage || permissionsOK) {
    return (
      <Route
        key={path.toString()}
        location={location}
        path={path}
        exact={exact}
        strict={strict}
        render={render}
      />
    );
  }

  return <>&nbsp;</>;
};

export default PrivateRoute;
