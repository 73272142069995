import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    // fonts
    --base-font: 'Prompt', sans-serif;

    --weight-light: 300;
    --weight-regular: 400;
    --weight-medium: 500;
    --weight-semi: 600;
    --weight-bold: 700;
    
    // colors
    --primary-color: rgb(0, 194, 255);

    --blue-color: var(--primary-color);
    --blue-color-hover: rgba(15, 167, 254, 0.8);
    --blue-color-active: rgb(15, 167, 254);

    --orange-color: rgb(252, 182, 34);
    --orange-color-hover: rgba(225, 144, 37, 0.8);
    --orange-color-active: rgb(225, 144, 37);

    --violet-color: rgb(73, 58, 252);
    --violet-color-hover: rgba(31, 18, 180, 0.8);
    --violet-color-active: rgb(31, 18, 180);
    
    --white-color: rgb(255, 255, 255);
    --tetradic-color: rgb(249, 250, 251);
    
    --border-color: rgb(221, 225, 230);
    --dark-border-color: rgba(14, 15, 17, 1);

    --base-background-color: var(--white-color);
    --header-background-color: var(--base-background-color);
    --header-border-color: var(--border-color);

    --primary-text-color: rgb(14, 15, 17);
    --secondary-text-color: rgb(99, 107, 117);
    --white-text-color: var(--white-color);
    --business-card-color: rgb(51, 49, 50);
    
    // sidebar
    --sidebar-background-color: var(--tetradic-color);
    --sidebar-border-color: var(--border-color);
    --sidebar-width: 470px;
    --sidebar-padding-vertical: 20px;
    --sidebar-padding-horizontal: 30px;
    
    // avatar creator
    --avatar-creator-border-radius: 6px;
    --avatar-creator-background-color: var(--white-color);
    --avatar-creator-border-color: var(--border-color);
    --avatar-creator-padding: 15px 15px 10px;

    // forms
    --input-border-radius: 6px;
    --input-border-color: var(--border-color);
    --input-background-color: var(--tetradic-color);
    
    // buttons
    --primaty-button-color: #00C2FF;
    --primaty-button-color-hover: #0FA7FE;
    --primaty-button-color-active: #00C2FF;

    --secondary-button-color: #E6F9FF;
    --secondary-button-color-hover: #BFF0FF;
    --secondary-button-color-active: #BFF0FF;
    
    --secondary-button-border-color: #80E0FF;
    --secondary-button-border-color-hover: #00C2FF;
    --secondary-button-border-color-active: #00C2FF;

    --z-index-header: 2;
    --z-index-modal: 3;

    --print-view-w: 9.1cm;
    --print-view-h: 54mm;

    --business-card-w: 9cm;
    --business-card-h: 5cm;

    --pass-card-w: 5.44cm;
    --pass-card-h: 8.65cm;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }
  
  img {
    display: inline-block;
    height: auto;
    image-rendering: optimizeQuality;
    border: 0;
  }
  
  textarea {
    overflow: auto;
    resize: none;
  }

  textarea,
  input,
  button {
    outline: none;

    &:focus {
      outline: none;
    }
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricPrecision;
    -webkit-font-feature-settings: 'liga'on;
    font-feature-settings: 'liga'on;
    -webkit-text-size-adjust: 100%;
  }

  html {
    font-size: 100%;
    line-height: 1.5;
    font-family: var(--base-font);
    background-color: var(--base-background-color);
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
  }

  body {
    width: 100%;
    height: 100%;
    font-weight: var(--weight-regular);
    color: var(--primary-text-color);
    margin: 0;
  }
  
  h3 {
    font-weight: var(--weight-semi);
    margin-bottom: 0;
  }
  
  button, input {
    font-family: var(--base-font);
  }
  
  button {
    cursor: pointer;
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .emptyMode {
    label {
      width: 70%;
      background: #EDF0F3 !important;
      color: transparent !important;
      
      span {
        color: inherit;
        opacity: 0;
      }
    }
    
    input, h3, h4, button {
      background: #EDF0F3 !important;
      color: transparent !important;
      border-radius: 0;
      border: none;
    }
  }
  @media print and (min-width: 1px) {
    body,
    * {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
      color-adjust: exact !important;
    }
  
    @page {
      font-family: 'Prompt', sans-serif;
      margin: 0 !important;
    }
  }
`;

export const PrintStyles = createGlobalStyle`
@media print and (min-width: 1px) {
    body,
    * {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        color-adjust: exact !important
    }
    .print-hidden {
        all: unset;
        display: none !important;
        opacity: 0 !important;
        visibility: hidden !important;
        height: 0px !important;
        position: absolute !important;
        top: -10231231px !important;
        left: -13012321px !important;
        width: 0px !important;
        overflow: hidden !important;
        line-height: 0 !important;
        padding: 0px !important;
        margin: 0px !important;
        border: none !important;
    }
}


${({ theme: { feature } }) => {
  if (feature === 'business-card') {
    return `
      @page {size: landscape;}

      @page {size: auto;}

      @page {
        size: 94mm 54mm !important;
        margin: 0mm !important;
        padding: 0mm !important;
        overflow: hidden;
        page-break-after: avoid;
        page-break-inside: avoid;
        background-color: var(--business-card-color);
        @top-left-corner { content: " "; border: solid green; }
        @top-right-corner { content: url(foo.png); border: solid green; }
        @bottom-right-corner { content: counter(page); border: solid green; }
        @bottom-left-corner { content: normal; border: solid green; }
        clip-path: inset(0 0 0 0);
      }
      
      @media print {
        a[href]:after {
            content: none !important;
        }
        *:after {
          vertical-align: top !important;
        }
        :root,
        html,
        body,
        #app {
          clip-path: inset(0 0 0 0);
          break-after: avoid;
          page-break-after: avoid;
          page-break-inside: avoid;
          background-color: var(--business-card-color);
          margin: 0 !important;
          padding: 0 !important;
          border: 0 !important;
          width: 100% !important;
          height: 100% !important;
          position: absolute !important;
          top: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
          left: 0 !important;
          border: 0 !important;
          overflow: hidden !important;
          transform: translate(0px, 0px);
          z-index: 100000;
          -ms-overflow-style: none;
          &::-webkit-scrollbar {
              display: none;
              width: 0 !important;
              height: 0 !important;
          }
        }
        ::-webkit-scrollbar {
            display: none;
            width: 0 !important;
            height: 0 !important;
        }
        *::-webkit-scrollbar {
            display: none;
            width: 0 !important;
            height: 0 !important;
        }
      }
    `;
  }
  if (feature === 'pass-card') {
    return `
    @page {size: portrait;}

    @page {size: auto;}

    @page {
      size: 58mm 88mm !important;
      margin: 0mm !important;
      padding: 0mm !important;
      overflow: hidden;
      page-break-after: avoid;
      page-break-inside: avoid;
      background-color: #e6f9ff;
      @top-left-corner { content: " "; border: solid green; }
      @top-right-corner { content: url(foo.png); border: solid green; }
      @bottom-right-corner { content: counter(page); border: solid green; }
      @bottom-left-corner { content: normal; border: solid green; }
    }
    
    @media print {
      a[href]:after {
          content: none !important;
      }

      *:after {
        vertical-align: top !important;
      }

      :root,
      html,
      body,
      #app {
        clip-path: inset(0 0 0 0);
        break-after: avoid;
        page-break-after: avoid;
        page-break-inside: avoid;
        background-color: #e6f9ff;
        margin: 0 !important;
        padding: 0 !important;
        border: 0 !important;
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        border: 0 !important;
        overflow: hidden !important;
        transform: translate(0px, 0px);
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
            width: 0 !important;
            height: 0 !important;
        }
      }
      ::-webkit-scrollbar {
          display: none;
          width: 0 !important;
          height: 0 !important;
      }
      *::-webkit-scrollbar {
          display: none;
          width: 0 !important;
          height: 0 !important;
      }
    }
    `;
  }
}}
`;
