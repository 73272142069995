import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import store from '@State/store';

import Routes from '@AppRoot/routes';
import history from '@AppRoot/history';
import { GlobalStyles } from '@AppRoot/styles/global';

const appContainer = document.getElementById('app')!;
const root = createRoot(appContainer);

root.render(
  <Provider store={store}>
    <GlobalStyles />
    <Router history={history} basename="/">
      <Routes />
    </Router>
  </Provider>,
);
