import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ModalInterface {
  modalTemplateName: string | null;
  templateData: any | null;
}

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    modalTemplateName: null,
    templateData: null,
  } as ModalInterface,
  reducers: {
    setModalTemplateName: (_, action: PayloadAction<ModalInterface>) => {
      const { modalTemplateName = null, templateData = null } = action?.payload || {};
      return {
        modalTemplateName,
        templateData,
      };
    },
  },
});

export const { setModalTemplateName } = modalSlice.actions;

export default modalSlice.reducer;
