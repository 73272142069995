/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiError = {
  properties: {
    details: {
      type: 'dictionary',
      contains: {
        properties: {},
      },
      isRequired: true,
    },
    message: {
      type: 'string',
      description: `HTTP Error code`,
      isRequired: true,
    },
  },
} as const;
