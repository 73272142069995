/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Office = {
  properties: {
    id: {
      type: 'string',
      description: `The id of the office`,
      isRequired: true,
    },
    phone: {
      type: 'string',
      description: `The phone number of the office`,
      isRequired: true,
    },
    label: {
      type: 'string',
      description: `The label of the office`,
      isRequired: true,
    },
  },
} as const;
