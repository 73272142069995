/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';
import type { UsersList } from '../models/UsersList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {
  /**
   * @returns User OK
   * @throws ApiError
   */
  public static getInfoMe(): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/me',
      errors: {
        400: `400 Failed`,
        401: `401 Failed`,
        403: `403 Failed`,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static searchDirectoryPeople({ query }: { query: string }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/search-directory-people',
      query: {
        query: query,
      },
      errors: {
        400: `400 Failed`,
        401: `401 Failed`,
        403: `403 Failed`,
      },
    });
  }

  /**
   * @returns User OK
   * @throws ApiError
   */
  public static getInfoUser({
    resourceNameId,
  }: {
    resourceNameId: string;
  }): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{resourceNameId}',
      path: {
        resourceNameId: resourceNameId,
      },
      errors: {
        400: `400 Failed`,
        401: `401 Failed`,
        403: `403 Failed`,
      },
    });
  }

  /**
   * @returns UsersList Ok
   * @throws ApiError
   */
  public static getUsers({
    pageSize,
    pageToken,
  }: {
    pageSize?: number;
    pageToken?: string;
  }): CancelablePromise<UsersList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users',
      query: {
        pageSize: pageSize,
        pageToken: pageToken,
      },
    });
  }
}
