import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { ThunkAction, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import reducer from '@State/rootReducer';
import initialState from '@State/initialState';
import { UnknownAsyncThunkAction } from '@reduxjs/toolkit/dist/matchers';

const logger = createLogger({ collapsed: true });

const middlewares = [...getDefaultMiddleware()];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const store = configureStore({
  reducer,
  middleware: middlewares,
  devTools: true,
  preloadedState: initialState,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type DispatchFunc = () => AppDispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  UnknownAsyncThunkAction
>

export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
