/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiError } from '../models/ApiError';
import type { OfficesList } from '../models/OfficesList';
import type { Partial_Office_ } from '../models/Partial_Office_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OfficesService {
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static getOffices(): CancelablePromise<OfficesList | ApiError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/offices',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static addOffices({
    requestBody,
  }: {
    requestBody: OfficesList;
  }): CancelablePromise<ApiError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/offices',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static restoreDefaults(): CancelablePromise<ApiError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/offices/restore-defaults',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateOffice({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: Partial_Office_;
  }): CancelablePromise<ApiError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/offices/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
