import { User, UsersList } from '@API/client';
import { ModalInterface } from './modal/modalSlice';
import { GoogleSignInState, SignInDataInitialState } from './signInData/signInDataSlice';
import { BACKGROUND_OPTIONS } from '@Constants/backgroundColors';

interface UserState extends User {
  media: {
    data: null;
    download: {
      rejected: boolean | null;
      pending: boolean | null;
      fulfilled: boolean | null;
    };
    upload: {
      rejected: boolean | null;
      pending: boolean | null;
      fulfilled: boolean | null;
    };
  };
}

const initialState = {
  signInData: SignInDataInitialState as GoogleSignInState,
  users: {
    list: [] as UsersList,
    options: [] as any,
    // @ts-ignore
    selectedUser: {
      isEditor: false,
      title: null,
      resourceName: null,
      resourceNameId: null,
      photo: null,
      phoneNumbers: null,
      name: null,
      etag: null,
      emailAddress: null,
      address: null,
      media: {
        data: null,
        download: {
          rejected: false,
          pending: false,
          fulfilled: false,
        },
        upload: {
          rejected: false,
          pending: false,
          fulfilled: false,
        },
        imageProcessing: {
          photo: null,
          rejected: false,
          pending: false,
          fulfilled: false,
        },
      },
      backgroundColor: BACKGROUND_OPTIONS?.[0],
      avatarActualisation: {
        photo: null,
        pending: false,
        rejected: false,
        fulfilled: false,
      },
    } as UserState,
    // @ts-ignore
    currentUser: {
      isEditor: false,
      title: null,
      resourceName: null,
      resourceNameId: null,
      photo: null,
      phoneNumbers: null,
      name: null,
      etag: null,
      emailAddress: null,
      address: null,
    } as User,
  },
  offices: [] as any, // TODO: type this
  modal: {
    modalTemplateName: null,
    templateData: null,
  } as ModalInterface,
};

export default initialState;
