import { OfficesList } from '@AppRoot/api/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import initialState from './initialState';

const officesSlice = createSlice({
  name: 'offices',
  initialState: initialState.offices || [],
  reducers: {
    setOffices: (_, action: PayloadAction<OfficesList>) => {
      const officesData = action?.payload || [];
      const options = officesData.map(({ label, id, phone }) => ({
        label,
        id,
        phone,
        value: id,
      }));
      
      return options;
    },
  },
});

export const { setOffices } = officesSlice.actions;

export default officesSlice.reducer;
