/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $EmailAddress = {
  properties: {
    type: {
      type: 'string',
      isRequired: true,
    },
    value: {
      type: 'string',
      isRequired: true,
      pattern: '^(.+)@(.+)$',
    },
  },
} as const;
