import { Switch, Route, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';

import PrivateRoute from '@Components/PrivateRoute';
const GAuth = loadable(() => import('@Components/GAuth'));
const ErrorView = loadable(() => import('@Components/ErrorView'));
const App = loadable(() => import('@AppRoot/App'));

const Routes = () => (
  <Switch>
    <Redirect from="." to="/profile/me/email-signature" exact />
    <Redirect from="/" to="/profile/me/email-signature" exact />
    <Redirect from="/profile" to="/profile/me/email-signature" exact />
    <Redirect from="/profile/me" to="/profile/me/email-signature" exact />
    <Redirect from="/user-editor" to="/user-editor/:resourceNameId?/email-signature" exact strict />

    <PrivateRoute
      path={[
        '/:viewType(user-editor|profile)/:resourceNameId?/:feature(email-signature|pass-card|business-card)',
      ]}
      permissions={['isEditor']}
      render={() => <App />}
      exact
    />

    <PrivateRoute path="/:auth(authorization)" render={() => <GAuth />} exact />

    <Route
      path="/page401"
      render={() => (
        <ErrorView
          code="401"
          title="Authentication Required"
          text="You are not authorized to view this page. Please log in to continue."
        />
      )}
      exact
    />
    <Route
      path="/page403"
      render={() => (
        <ErrorView
          code="403"
          title="Insufficient Permissions"
          text="Sorry, you don’t have enough permission to access this page. Please contact your account administrator for assistance."
        />
      )}
      exact
    />
    <Route path="/page50X" render={() => <ErrorView />} exact />
    <Route
      path={['/page50X', '*']}
      render={() => (
        <ErrorView
          code="50X"
          title="Something went wrong. Please try again later."
          text="If issue persists, please contact your account administrator."
          showBackButton
        />
      )}
    />
  </Switch>
);

export default Routes;
