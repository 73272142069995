import { combineReducers } from '@reduxjs/toolkit';

import signInData from './signInData/signInDataSlice';
import modal from './modal/modalSlice';
import users from './usersSlice';
import offices from './officesSlice';

const rootReducer = combineReducers({
  users,
  offices,
  modal,
  signInData,
});

export default rootReducer;
