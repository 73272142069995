/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Partial_Office_ = {
  description: `Make all properties in T optional`,
  properties: {
    label: {
      type: 'string',
      description: `The label of the office`,
    },
    phone: {
      type: 'string',
      description: `The phone number of the office`,
    },
    id: {
      type: 'string',
      description: `The id of the office`,
    },
  },
} as const;
