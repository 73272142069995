/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $User = {
  properties: {
    isEditor: {
      type: 'boolean',
    },
    title: {
      type: 'string',
    },
    resourceName: {
      type: 'string',
      isRequired: true,
    },
    photo: {
      type: 'Photo',
    },
    phoneNumbers: {
      type: 'array',
      contains: {
        type: 'PhoneNumber',
      },
    },
    name: {
      type: 'Name',
      isRequired: true,
    },
    etag: {
      type: 'string',
      isRequired: true,
    },
    emailAddress: {
      type: 'array',
      contains: {
        type: 'EmailAddress',
      },
    },
    address: {
      type: 'array',
      contains: {
        type: 'Address',
      },
    },
  },
} as const;
